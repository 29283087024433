import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import Typography from "@material-ui/core/Typography"
import CardMedia from "@material-ui/core/CardMedia"
import fillersStripe from "../../images/fillersStripe.png"
import faceLiftStripe from "../../images/faceLiftStripe.png"
import correctiveStripe from "../../images/correctiveStripe.png"
import education from "../../images/education.png"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"

const useStyles = makeStyles((theme) => ({
  card: {
    margin: "7em 0em",
    paddingBottom: "4em",
    backgroundColor: "#F8F4F5",
  },
  details: {},
  icon: {
    position: "absolute",
    backgroundPosition: "0 0",
    backgroundRepeat: "no-repeat",
    width: "176px",
    height: "176px",
  },
  content: {
    padding: "4em 13em 2em 13em",
    [theme.breakpoints.down("sm")]: {
      padding: "6em 1em 2em 1em",
    },
  },
  name: {
    color: "#9D989A",
    textTransform: "uppercase",
    margin: "1.4em 0em 0.4em 0em",
    paddingBottom: "0px",
  },
  title: {
    color: "#9D989A",
    fontSize: "18px",
    paddingBottom: "2px",
  },
  presentationText: {
    paddingTop: "20px",
    fontSize: "1.1rem",
    ["@media (max-width:600px)"]: {
      fontSize: "0.8rem",
    },
  },
  image: {
    marginLeft: "30px",
    "&:hover": {
      opacity: 0.5,
      backgroundColor: "#929B91",
      cursor: "pointer",
    },
    maxWidth: "300px",
    ["@media (max-width:600px)"]: {
      maxWidth: "170px",
    },
  },
  fillersStripe: {
    backgroundImage: `url(${fillersStripe})`,
  },
  faceLiftStripe: {
    backgroundImage: `url(${faceLiftStripe})`,
  },
  correctiveStripe: {
    backgroundImage: `url(${correctiveStripe})`,
  },
  education: {
    backgroundImage: `url(${education})`,
  },
  imageItem: {
    display: "flex",
    justifyContent: "flex-start",
    padding: "4em 13em 2em 13em",
    [theme.breakpoints.down("md")]: {
      padding: "6em 2em 2em 2em",
    },
  },
  bookCourseButton: {
    color: "#fff",
    fontSize: "12px",
    backgroundColor: "#929B91",
    borderRadius: "40px",
    padding: "1.2em 4em",
    marginTop: "2em",
    marginBottom: "2em",
    textDecoration: "none",
    alignSelf: "flex-end",
    "&:hover": {
      textDecoration: "none",
      opacity: 0.5,
      backgroundColor: "#929B91",
    },
    ["@media (max-width:780px)"]: {
      // eslint-disable-line no-useless-computed-key
      display: "block",
      marginTop: "12px",
    },
    // [theme.breakpoints.down('xs')]: {
    //   marginTop: '24px',
    //   marginBottom: '24px',
    // },
  },
}))

const CourseCard = ({
  imgLeft,
  imgRight,
  iconName,
  name,
  title,
  contentClick1,
  contentClick2,
  children,
  id,
}) => {
  const classes = useStyles()
  return (
    <Card className={classes.card} id={id}>
      <div className={`${classes[iconName]} ${classes.icon}`}></div>
      <Box className={classes.content}>
        <Typography component="h2" variant="h4" className={classes.name}>
          {name}
        </Typography>
        <Typography component="h4" variant="p" className={classes.title}>
          {title}
        </Typography>
        <Typography
          component="p"
          variant="p"
          className={classes.presentationText}
          color="textSecondary"
        >
          {children}
        </Typography>
        {/* <Button
          className={classes.bookCourseButton}
          data-leadbox-popup="KkdXS4oqXat38HrM7DuvrA"
          data-leadbox-domain="kooldigital.lpages.co"
        >
          REGISTER TO COURSE
        </Button> */}
      </Box>
      <Box className={classes.imageItem}>
        <CardMedia
          className={classes.image}
          alt={title}
          src={imgLeft}
          component={"img"}
          onClick={contentClick1}
        />
        <CardMedia
          className={classes.image}
          alt={title}
          src={imgRight}
          component={"img"}
          onClick={contentClick2}
        />
      </Box>
    </Card>
  )
}

export default CourseCard
